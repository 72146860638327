<template>
    <div class="exchangeMembershipAppsDashboard">
        <div class="exchangeMembershipAppsDashboard__header">
            <div class="exchangeMembershipAppsDashboard__title">Exchange membership applications</div>
        </div>
        <div class="exchangeMembershipAppsDashboard__button">
          <span class="text" @click="exportToExcel">Export</span>
        </div>
    </div>
</template>

<script>
import './ExchangeMembershipApps.styl'
import { reportsAPI } from '@/api/reports'

export default {
    name: 'exchange-membership-apps',
    methods: {
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.exchangeMembershipApps()
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'exchangeMembershipApps_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
    },
}
</script>